
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
  toRaw,
} from 'vue';
import avator from "@/components/tools/avator.vue";
import {useStore} from "vuex";
import {ElUpload} from 'element-plus'
import {ElLoading, ElMessageBox} from 'element-plus';
import {useRoute, useRouter} from 'vue-router';
import axios from '@/api/http.js';

// 定义一个接口，约束imgBox的类型
interface Img {
  img: string;
  id: number;
  title?: string; //问号代表可有可无
  name?: string; //问号代表可有可无
  description?: string; //问号代表可有可无
  // id: string; 如果定义是String类型但是数据是number类型的就会提示出错
}

export default defineComponent({
  name: 'Profile',
  components: {
    avator,
    ElUpload
  },
  setup() {
    //============= data ==============
    const demoImg = ref("")
    const store = useStore();
    const client = localStorage.getItem("eventClient");
    const formRef = ref(null);
    const fileRef = ref(null);
    const data = reactive({
      firstInData: {},//第一次进去页面的初始数据
      avatorBase: "",
      showUploadDialog: false,
      campusInfo: "",
      userInfo: {},
      form: {
        firstName: "",
        lastName: "",
        title: "",
        company: "",
        phone: "",
        email: "",
        bio: "",
      },
      rules: {
        firstName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        lastName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        title: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        company: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],

      }
    });

    const init = () => {
      let campusInfo = JSON.parse(localStorage.getItem("campusInfo"));
      data.campusInfo = campusInfo;
      // data.form.firstName = campusInfo.firstName;
      // data.form.lastName = campusInfo.lastName;
      // data.form.title = campusInfo.title;
      // data.form.company = campusInfo.company;
      // data.form.phone = campusInfo.phone;
      // data.form.email = campusInfo.email;
      // data.form.bio = campusInfo.bio;
    }
    init();
    const getProfile = async () => {
      await axios.request({
        method: 'GET',
        url: '/m/profile/',
      }).then(res => {
        localStorage.campusInfo = JSON.stringify(res.data);
      }).catch(err => {
        console.log(err);
      })
    }
    const save = () => {
      let userId = JSON.parse(localStorage.getItem("campusInfo")).userId;
      formRef.value.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true,
          })
          axios.request({
            method: 'POST',
            url: '/m/update-profile/',
            data: {
              meetingId: "mg9lr5rzd62to2hcidef11id",
              userId: userId,
              firstName: data.form.firstName,
              title: data.form.title,
              lastName: data.form.lastName,
              company: data.form.company,
              email: data.form.email,
              bio: data.form.bio,
              imageData: demoImg["src"] ? demoImg["src"] : "",
            }
          }).then(res => {
            console.log('res:', res);
            loading.close();
            ElMessageBox.alert('Your Profile has been updated!', {
              confirmButtonText: 'OK',
            }).then(async (res) => {
              getUserInfo();
              await getProfile();//获取最新Profile信息
              let ischange = store.state.avator.avatorChange;//在topBar有监听到数据变化则刷新视图
              store.commit("setAvatorChange", !ischange);
            })
          }).catch(err => {
            loading.close();
            console.log(err);
          })
        } else {
          return false
        }
      })
    }

    const getUserInfo = () => {
      let userId = JSON.parse(localStorage.getItem("campusInfo")).userId;
      axios.request({
        method: 'PATCH',
        url: '/virtual/attendees',
        data: {
          meetingId: "mg9lr5rzd62to2hcidef11id",
          userId: userId,
        }
      }).then(res => {
        let userInfo = res.data.data[0];
        data.firstInData = res.data.data[0];
        data.userInfo = userInfo;
        data.userInfo["firstCharacter"] = userInfo.firstName[0];
        data.userInfo["lastCharacter"] = userInfo.lastName[0];
        data.form.firstName = userInfo.firstName;
        data.form.lastName = userInfo.lastName;
        data.form.title = userInfo.title;
        data.form.company = userInfo.company;
        data.form.phone = userInfo.phone;
        data.form.email = userInfo.email;
        data.form.bio = userInfo.bio;
      }).catch(err => {
        console.log(err);
      })
    }
    getUserInfo();
    const cropSuccess = (imgDataUrl, field) => {
      console.log("==== imgDataUrl=====", imgDataUrl)
      console.log("==== field=====", field)
    };
    const demo = (e) => {//预览图片 并将图片转为base64码传给后台

      let file = e.target.files[0]
      console.log("==== file=====", file)
      let reader = new FileReader()
      if (!(file.type == 'image/jpg' || file.type == 'image/png')) {
        ElMessageBox.alert('Only supports png or jpg format types', {
          confirmButtonText: 'OK',
        })
        return
      }
      //
      if (file.size / 1024 / 1024 > 2) {
        ElMessageBox.alert('File size cannot be larger than 2MB', {
          confirmButtonText: 'OK',
        })
        return
      }
      reader.readAsDataURL(file)    //将结果转为url，使得img的src可以使用
      reader.onload = (e) => {
        data["userInfo"] = {};
        data["avatorBase"] = e["target"]["result"]["split"](',')[1];
        demoImg["src"] = e.target.result;//转为url后的结果 赋给img的src
        console.log("====data.firstInData =====",data.firstInData)
        console.log("====data.firstInData =====",toRaw(data.firstInData))
        data.userInfo["name"] = data.firstInData["name"] || "";
        data.userInfo["title"] = data.firstInData["title"] || "";
        data.userInfo["company"] = data.firstInData["company"] || "";
        data.userInfo["image"] = e.target.result;
      }
    }

    return {
      ...toRefs(data),
      demoImg,
      formRef,
      fileRef,
      save,
      cropSuccess,
      store,
      demo
    };
  },
});
